<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" fill="none">
        <path fill="currentColor" d="M7.502.5h1.996v7.002H16.5v1.996H9.498V16.5H7.502V9.498H.5V7.502h7.002V.5Z" />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
